export interface AddressesType {
  // Protocol
  ADDRESS_PROVIDER: string;
  FACTORY: string;
  LT_HELPER: string;
  STAKER: string;
  LOCKER: string;
  PARAMETER_PROVIDER: string;
  REFERRALS: string;
  SYNTHETIX_HANDLER: string;
  TLX: string;
  VESTING: string;
  ZAP_SWAP: string;
  SYNTHETIX_STORAGE: string;
  WRAPPER_HELPER: string;

  // Contracts
  VELODROME_POOL: string;
  VELODROME_GAUGE: string;
  MULTICALL: string;
  PERPS_MARKET: string;
  MULTIPLE_MERKLE_DISTRIBUTOR: string;

  // Tokens
  SUSD: string;
  VELO: string;
  USDC: string;
  USDCE: string;
  DAI: string;
  USDT: string;
  WETH: string;
  OP: string;
}

const ADDRESSES_PER_CHAIN: { [chainId: number]: AddressesType } = {
  10: {
    // Protocol
    ADDRESS_PROVIDER: "0xbaa87ecc5dd76526b51ab7fd2d0c814eb967e2e2",
    FACTORY: "0x5Dd85f51e9fD6aDE8ecc216C07919ecD443eB14d",
    LT_HELPER: "0x8E61715363435d40B0Dbb35524a03517b9d8d01D",
    STAKER: "0xc30877315f3b621a8f7bcda27819ec29429f3817",
    LOCKER: "0xc068c3261522c97ff719dc97c98c63a1356fef0f",
    PARAMETER_PROVIDER: "0xd85c61e96fd1805f0de12165ca126243617a57b0",
    REFERRALS: "0x756869855e6857E6618BD71646B23F69A054a8c7",
    SYNTHETIX_HANDLER: "0xa86B0c4Cf984Cf2cf47CCDF96462fe40cD92D46E",
    TLX: "0xd9cc3d70e730503e7f28c1b407389198c4b75fa2",
    VESTING: "0xebd707214ea4bae19e826f14ec2a82d1b44a75f3",
    ZAP_SWAP: "0x28b82692c8010C0f73b32822Ed1e480c9032b6CB",
    SYNTHETIX_STORAGE: "0xDbf28C96ACEBd4d1b8c1DC4DA4cFc25302704b7e",
    WRAPPER_HELPER: "",

    // Contracts
    VELODROME_POOL: "0x6eA20Dbc05f58ED607c374e545BFb402d5796770",
    VELODROME_GAUGE: "0x3132cC562C39Fb9ABaeA11411987031C5144C85D",
    MULTICALL: "0xcA11bde05977b3631167028862bE2a173976CA11",
    PERPS_MARKET: "",
    MULTIPLE_MERKLE_DISTRIBUTOR: "0x6FA4b55c8b9AdC03129c3796414d44cA0ef50764",

    // Tokens
    SUSD: "0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9",
    VELO: "0x3c8B650257cFb5f272f799F5e2b4e65093a11a05",
    USDC: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    USDCE: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    DAI: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    USDT: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    WETH: "0x4200000000000000000000000000000000000006",
    OP: "0x4200000000000000000000000000000000000042",
  },
  8453: {
    // Protocol
    ADDRESS_PROVIDER: "0x88082a5c4f175A185227595bd6B8043826c76093",
    FACTORY: "0x7Cee568F484bd09D1D270f860BCa0f080c6a37E5",
    LT_HELPER: "0x4C502c1b05da0E560A9F39E5691B1F9929038a3E",
    STAKER: "",
    LOCKER: "",
    PARAMETER_PROVIDER: "0x0D19A21AddC0711328b302d0b55ac79ca25813D3",
    REFERRALS: "",
    SYNTHETIX_HANDLER: "",
    TLX: "",
    VESTING: "",
    ZAP_SWAP: "",
    SYNTHETIX_STORAGE: "",
    WRAPPER_HELPER: "0xb9b5426538664f95b9E7d7E609D14BE7F9F9Da4D",

    // Contracts
    VELODROME_POOL: "",
    VELODROME_GAUGE: "",
    MULTICALL: "0xcA11bde05977b3631167028862bE2a173976CA11",
    PERPS_MARKET: "0x0A2AF931eFFd34b81ebcc57E3d3c9B1E1dE1C9Ce",
    MULTIPLE_MERKLE_DISTRIBUTOR: "0x92cDd8De4bF6172EBE3Cd2e2E9CAFC37b318D350",

    SUSD: "",
    VELO: "",
    USDC: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    USDCE: "",
    DAI: "",
    USDT: "",
    WETH: "",
    OP: "",
  },
};

export default ADDRESSES_PER_CHAIN;
