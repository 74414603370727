import styled, { keyframes } from "styled-components";

import Button from "../../components/Button";
import Radio, { RadioOption } from "../../components/Radio";
import { useState } from "react";
import Switch from "../../components/Switch";
import AssetSelector from "../../components/AssetSelector";

import { GlossFrame, SubCardFrame } from "../../styles/Frames";
import { Splitter } from "../../styles/utils";
import { useNavigate } from "react-router-dom";

import synthetix from "../../assets/supporters/synthetix.svg";
import { MINT_PATH } from "../../app/constants/paths";
import Seo from "../../components/Seo";
import discord from "../../assets/socials/discord-black.svg";
import { DISCORD } from "../../app/constants/urls";
import useChainData from "../../app/web3/utils/use-chain-data";
import useMainChainData from "../../app/web3/utils/use-main-chain-data";
import SUPPORTED_CHAINS from "../../app/constants/supported-chains";

const LEVERAGE_OPTIONS = [2, 3, 4, 5, 10];

const HIGHLIGHT_TEXT: string | null = null;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const StyledHero = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const Header = styled.h1`
  font-size: 6.8rem;
  font-weight: 700;
  max-width: 70rem;
  color: var(--main);
  text-align: left;

  @media (max-width: 900px) {
    font-size: 4.4rem;
    width: 100%;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2.4rem;

  @media (max-width: 900px) {
    margin-top: 2.8rem;
    margin-bottom: 7rem;
  }
`;

const SubHeader = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  color: var(--sub);

  @media (max-width: 900px) {
    font-size: 2.4rem;
    width: 100%;
  }
`;

const UiOverlay = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const UiParent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const UiContainer = styled(SubCardFrame)`
  padding: 1.6rem 2.4rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;
  justify-items: center;
  width: 32rem;
  position: relative;
`;

const animateUp = keyframes`
  from {
    transform: translateY(9.5rem);
  }
  to {
    transform: translateY(3.8rem);
  }
`;

const HighlightContainer = styled(GlossFrame)`
  width: 100%;
  padding: 1.6rem 2.5rem;
  padding-bottom: 5.4rem;
  transform: translateY(9.5rem);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  color: var(--main);
  border-radius: var(--border-radius);

  animation: ${animateUp} 0.5s 1s ease-out forwards;
`;

const PoweredByLink = styled.a`
  height: 1.3rem;
  margin: auto;
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
`;

const PoweredByText = styled.div`
  color: var(--sub);
  font-size: 1.1rem;
  font-weight: 500;
`;

const PoweredByLogo = styled.img`
  width: 8.5rem;
  margin-left: 0.5rem;
  opacity: 0.8;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const HighlightButtonContainer = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

const Hero = () => {
  const navigate = useNavigate();
  const defaultAssetId = "ETH";
  const [assetId, setAssetId] = useState<string>(defaultAssetId);
  const chainData = useChainData();
  const mainChainData = useMainChainData();
  const leverageOptions: RadioOption[] = LEVERAGE_OPTIONS.map((value) => ({
    label: `${value}X`,
    value: value.toString(),
    comingSoon: !(chainData?.leveragedTokens ?? mainChainData.leveragedTokens)[
      assetId
    ].includes(value),
  }));
  const defaultLeverage = leverageOptions[1];
  const [leverage, setLeverage] = useState(defaultLeverage);

  const hasOnlyOneLeverageOption =
    leverageOptions.filter((option) => !option.comingSoon).length === 1;

  const directionPrefix = hasOnlyOneLeverageOption
    ? `${defaultLeverage.value}x `
    : "";

  const defaultDirection = "long";

  const [direction, setDirection] = useState(defaultDirection);

  const touched =
    leverage !== defaultLeverage ||
    direction !== defaultDirection ||
    assetId !== defaultAssetId;

  return (
    <Container>
      <Seo
        title="Synthetix - Leveraged Tokens"
        description="Synthetix offers Leveraged tokens for 10+ assets with up to 7x leverage. Discover the most streamlined trading experience on Optimism."
      />
      <StyledHero>
        <HeroContent>
          <Header>The Simplest Way to Trade with Leverage</Header>
          <SubHeaderContainer>
            <SubHeader>
              Leveraged tokens for 10+ assets with up to 7x leverage
            </SubHeader>
          </SubHeaderContainer>
          <HighlightButtonContainer>
            <Button
              icon={discord}
              action={() => {
                window.open(DISCORD, "_blank")?.focus();
              }}
            >
              Join the community
            </Button>
          </HighlightButtonContainer>
        </HeroContent>
      </StyledHero>
      <UiOverlay>
        <UiParent>
          {HIGHLIGHT_TEXT !== null && (
            <HighlightContainer>{HIGHLIGHT_TEXT}</HighlightContainer>
          )}
          <UiContainer>
            <AssetSelector active={assetId} setActive={setAssetId} />
            <Splitter>
              <Switch
                switches={[
                  { label: `${directionPrefix}Long`, value: "long" },
                  {
                    label: `${directionPrefix}Short`,
                    value: "short",
                    comingSoon: !!chainData
                      ? !chainData.shortingEnabled
                      : !Object.keys(
                          SUPPORTED_CHAINS[1].leveragedTokens
                        ).includes(assetId),
                    isNew: true,
                  },
                ]}
                active={direction}
                setActive={(option: string) => setDirection(option)}
                big={hasOnlyOneLeverageOption}
              />
            </Splitter>
            {!hasOnlyOneLeverageOption && (
              <Splitter>
                <Radio
                  active={leverage}
                  setActive={(option: RadioOption) => setLeverage(option)}
                  options={leverageOptions}
                />
              </Splitter>
            )}
            <Splitter>
              <ButtonContainer>
                <Button
                  primary={touched}
                  wide
                  action={() =>
                    navigate(
                      `/${MINT_PATH}?asset=${assetId}&direction=${direction.toLowerCase()}&leverage=${
                        leverage.value
                      }`
                    )
                  }
                >
                  Mint Leveraged Tokens
                </Button>
              </ButtonContainer>
              <PoweredByLink
                href="https://synthetix.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PoweredByText>powered by</PoweredByText>
                <PoweredByLogo src={synthetix} alt="Synthetix" />
              </PoweredByLink>
            </Splitter>
          </UiContainer>
        </UiParent>
      </UiOverlay>
    </Container>
  );
};

export default Hero;
