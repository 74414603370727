import { styled as materialStyled } from "@mui/material/styles";
import styled from "styled-components";
import LeaderBoardTable from "../../components/LeaderboardTable";
import { MenuItem, Select } from "@mui/material";
import { useState } from "react";

const Title = styled.h2`
  font-size: 3.2rem;
  font-weight: 700;
  color: var(--main);
  margin: 2.4rem 0;
`;

const Text = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--sub);
`;

const LinkExternal = styled.a`
  cursor: pointer;
  color: var(--primary);
  font-size: 1.4rem;
  font-weight: 400;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  gap: 2.4rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const CustomMenuItem = materialStyled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  padding: "10px 20px",
  fontSize: "16px",
}));

const CustomSelect = materialStyled(Select)(() => ({
  color: "var(--main)",
  border: "var(--border)",
  borderRadius: "var(--border-radius)",
  fontSize: "1.8rem",
  width: "13.7rem",
  height: "4.8rem",
  fontWeight: 700,
  "& .MuiSelect-icon": {
    color: "var(--main)",
  },
  "&:focus": {
    borderColor: "var(--sub)",
  },
  "&:active": {
    borderColor: "var(--sub)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--sub)",
  },
}));

const LeaderBoard = () => {
  const [week, setWeek] = useState(5);

  return (
    <>
      <Title>Leaderboard</Title>
      <TopSection>
        <Text>
          Synthetix is rewarding users who mint leverage tokens for a five week
          Leverage Rally! Check your estimated rewards on the leaderboard below.
          Learn more about the incentive program{" "}
          <LinkExternal
            href="https://blog.synthetix.io/synthetix-leveraged-token-rally/ "
            target="_blank"
          >
            here
          </LinkExternal>
          .
        </Text>

        <CustomSelect
          value={week}
          onChange={(e) => {
            if (!e.target.value) return;
            if (typeof e.target.value !== "number") return;
            setWeek(e.target.value);
          }}
        >
          <CustomMenuItem value={1}>Week 1</CustomMenuItem>
          <CustomMenuItem value={2}>Week 2</CustomMenuItem>
          <CustomMenuItem value={3}>Week 3</CustomMenuItem>
          <CustomMenuItem value={4}>Week 4</CustomMenuItem>
          <CustomMenuItem value={5}>Week 5</CustomMenuItem>
        </CustomSelect>
      </TopSection>

      <LeaderBoardTable week={week} />
    </>
  );
};

export default LeaderBoard;
