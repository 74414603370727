import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ScaledNumber } from "scaled-number";
import { setWarning } from "../../../state/uiSlice";
import useSynthetixMarket from "../contracts/use-synthetix-market";
import { LeveragedTokenDataType } from "./use-leveraged-token-data";
import useChainData from "../utils/use-chain-data";
import usePerpsMarket from "../contracts/use-perps-market";
import useLeveragedToken from "../contracts/use-leveraged-token";

const useAccessibleMargin = (
  leveragedTokenData: LeveragedTokenDataType | null
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const market = useSynthetixMarket(leveragedTokenData?.targetAsset || null);
  const perpsMarket = usePerpsMarket();
  const leveragedToken = useLeveragedToken(leveragedTokenData?.addr || null);
  const chainData = useChainData();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);

  const isV2 = chainData?.v2;

  const addr = leveragedTokenData?.addr;
  useEffect(() => {
    const getAccessibleMargin = async () => {
      if (isV2) {
        if (!perpsMarket) return;
        if (!leveragedToken) return;

        const accountId = await leveragedToken.accountId();
        const withdrawableMargin_ = await perpsMarket.getWithdrawableMargin(
          accountId
        );
        setAmount(new ScaledNumber(withdrawableMargin_));
        return;
      }

      if (!market) return;
      if (!addr) return;

      try {
        const accessibleMargin_ = await market.accessibleMargin(addr);
        setAmount(new ScaledNumber(accessibleMargin_[0]));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching accessible margin"));
      }
    };

    getAccessibleMargin();
  }, [dispatch, addr, market, isV2, perpsMarket, leveragedToken]);

  return amount;
};

export default useAccessibleMargin;
