import useContract from "../utils/use-contract";
import abi from "../abis/multiple-merkle-distributor-abi.json";
import useAddresses from "../utils/use-addresses";

const useMultipleMerkleDistributor = () => {
  const addresses = useAddresses();
  return useContract(addresses?.MULTIPLE_MERKLE_DISTRIBUTOR, abi);
};

export default useMultipleMerkleDistributor;
