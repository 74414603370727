import styled from "styled-components";
import newIcon from "../assets/ui/new.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 2.6rem;
  transform: translate(115%, -40%);

  @media (max-width: 900px) {
    height: 2.4rem;
  }
`;

interface Props {
  children: React.ReactNode;
  isNew?: boolean;
}

const NewContainer = ({ children, isNew }: Props) => {
  return (
    <Container>
      {children}
      {isNew && <NewIcon src={newIcon} />}
    </Container>
  );
};

export default NewContainer;
