import styled from "styled-components";
import { LeveragedTokenDataType } from "../../app/web3/views/use-leveraged-token-data";
import { CardFrame } from "../../styles/Frames";
import Kebab from "../../components/Kebab";
import { useNavigate } from "react-router-dom";
import { MINT_PATH } from "../../app/constants/paths";
import susd from "../../assets/ui/susd.svg";
// import Chart from "../../components/Chart";
import { getAssetData } from "../../app/helpers/get-asset-data";
import Button from "../../components/Button";
import { useState } from "react";
import RedeemLeveragedToken from "./RedeemLeveragedToken";
import { PNL_LIVE } from "../../app/constants/config";
// import useLeveragedTokenChart from "../../app/hooks/use-leveraged-token-chart";
import getLeveragedTokenIcon from "../../app/helpers/get-leveraged-token-icon";
import useBlockExplorerLink from "../../app/web3/utils/use-block-explorer-link";

const StyledLeveragedTokenData = styled(CardFrame)`
  padding: 1.6rem;
  display: grid;
  grid-gap: 2.4rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenSymbol = styled.div`
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
`;

const SmallRow = styled.div`
  display: flex;
  align-items: center;
`;

const SusdValue = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 0.4rem;
`;

const SusdIcon = styled.img`
  width: 1.6rem;
`;

// const ChartContainer = styled.div`
//   height: 4.8rem;
//   width: 8rem;
// `;

const SmallHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--sub);
  margin-bottom: 0.4rem;
`;

const SmalLValue = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
`;

const LeveragedTokenIcon = styled.img`
  height: 5rem;
`;

interface Props {
  data: LeveragedTokenDataType;
}

const LeveragedTokenData = ({ data }: Props) => {
  const navigate = useNavigate();
  const blockExplorerLink = useBlockExplorerLink();

  const assetMetadata = getAssetData(data.targetAsset);
  if (!assetMetadata) throw new Error("No asset metadata");
  // TODO: Enable chart again once we query this from an API instead of from the blockchain
  // const chart = useLeveragedTokenChart(data.addr);

  const [redeeming, setRedeeming] = useState(false);

  return (
    <>
      <StyledLeveragedTokenData>
        <Row>
          <LeveragedTokenIcon src={getLeveragedTokenIcon(data.symbol)} />
          <Kebab
            items={[
              {
                label: "Mint more",
                action: () =>
                  navigate(
                    `/${MINT_PATH}?asset=${data.targetAsset}&leverage=${
                      data.targetLeverage
                    }&direction=${data.isLong ? "long" : "short"}`
                  ),
              },
              {
                label: "View on block explorer",
                action: () =>
                  window.open(blockExplorerLink(data.addr), "_blank")?.focus(),
              },
            ]}
          />
        </Row>
        <Row>
          <TokenSymbol>{data.symbol}</TokenSymbol>
          {/* {chart && (
            <ChartContainer>
              <Chart color={assetMetadata.color} simple chart={chart} />
            </ChartContainer>
          )} */}
        </Row>
        <Row>
          <Column>
            <SmallHeader>Leverage</SmallHeader>
            <SmalLValue>{`${data.leverage.toNumber().toFixed(1)}x`}</SmalLValue>
          </Column>
          {PNL_LIVE ? (
            <Column>
              <SmallHeader>Return</SmallHeader>
              <SmalLValue>TODO%</SmalLValue>
            </Column>
          ) : (
            <Column>
              <SmallHeader>Balance</SmallHeader>
              <SmallRow>
                <SusdValue>
                  {data.userBalance
                    ? data.userBalance.mul(data.exchangeRate).toCryptoString()
                    : "---"}
                </SusdValue>
                <SusdIcon src={susd} alt="sUSD" />
              </SmallRow>
            </Column>
          )}
        </Row>
        <Button wide action={() => setRedeeming(true)}>
          Redeem
        </Button>
      </StyledLeveragedTokenData>
      {redeeming && (
        <RedeemLeveragedToken
          open
          close={() => setRedeeming(false)}
          data={data}
        />
      )}
    </>
  );
};

export default LeveragedTokenData;
