import BadgeImage from "../assets/ui/rank-badge.svg";
import styled from "styled-components";
import { shortenAddress, useEthers } from "@usedapp/core";
import useChainData from "../app/web3/utils/use-chain-data";
import useLeaderboard from "../app/hooks/use-leaderboard";
import { useEffect, useMemo, useState } from "react";
import Tooltip from "./Tooltip";
import { usePaginate } from "../app/hooks/use-paginate";
import Pagination from "./Pagination";
import { getEpoch, getRewardToken } from "../app/helpers/leaderboard";
import round from "../app/helpers/round";

const TableContainer = styled.div`
  border: var(--border);
  border-radius: var(--border-radius);
  width: 100%;

  @media (max-width: 900px) {
    overflow: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-bottom: var(--border);
`;

const Thead = styled.thead``;

const Th = styled.th<{ $rightAlign?: boolean; $small?: boolean }>`
  padding: 0.8rem 1.6rem;
  color: var(--sub);
  border: none;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
  justify-content: ${(props) =>
    props.$rightAlign ? "flex-end" : "flex-start"};
  display: flex;
  flex: ${(props) => (props.$small ? "none" : 1)};
  width: ${(props) => (props.$small ? "6.4rem" : "auto")};
`;

const Tbody = styled.tbody``;

const Tr = styled.tr<{
  $borderLeft?: boolean;
  $bgGradient?: string;
}>`
  border-left: ${(props) =>
    props.$borderLeft ? "1px solid var(--primary)" : undefined};
  background: ${(props) => props.$bgGradient};
  display: flex;
`;

const Td = styled.td<{
  $rightAlign?: boolean;
  $small?: boolean;
  $padding?: string;
}>`
  padding: ${(props) => props.$padding || "1.6rem"};
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
  border: none;
  justify-content: ${(props) =>
    props.$rightAlign ? "flex-end" : "flex-start"};
  display: flex;
  align-items: center;
  flex: ${(props) => (props.$small ? "none" : 1)};
  width: ${(props) => (props.$small ? "6.4rem" : "auto")};
  gap: 1rem;
`;

const Badge = styled.div`
  width: 1.8rem;
  height: 2.682rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${BadgeImage});
  font-weight: 600;
`;

const Align = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;
  gap: 1rem;
  font-weight: 400;
  font-size: 1.2rem;
`;

const Icon = styled.img`
  height: 1.6rem;
  border-radius: 50%;
  font-weight: 600;
`;

const Empty = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
  padding: 1.6rem;
  color: var(--sub);
`;

const getColor = (rank: number) => {
  switch (rank) {
    case 1:
      return "linear-gradient(270deg, rgba(0, 209, 255, 0) 0%, rgba(0, 209, 255, 0.2) 100%)";
    case 2:
      return "linear-gradient(270deg, rgba(0, 209, 255, 0) 0%, rgba(0, 209, 255, 0.14) 100%)";
    case 3:
      return "linear-gradient(270deg, rgba(0, 209, 255, 0) 0%, rgba(0, 209, 255, 0.08) 100%)";

    default:
      break;
  }
};

interface Props {
  week: number;
}

const PAGE_SIZE = 10;

const LeaderBoardTable = ({ week }: Props) => {
  const chainData = useChainData();
  const { data, isLoading } = useLeaderboard(chainData?.chainId);
  const { account } = useEthers();
  const [page, setPage] = useState(0);

  const filteredList = useMemo(() => {
    return data?.filter((item) => {
      const epoch = getEpoch(item.date.getTime()).epoch;
      return week === epoch;
    });
  }, [data, week]);

  const currentUserData = useMemo(() => {
    return filteredList?.find(
      (item) => item.account.toLowerCase() === account?.toLowerCase()
    );
  }, [account, filteredList]);

  const items = useMemo(() => {
    return filteredList?.filter(
      (item) => item.account.toLowerCase() !== account?.toLowerCase()
    );
  }, [account, filteredList]);

  const rewardToken = getRewardToken(chainData?.chainId);

  const {
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    totalPages,
    onPreviousPage,
    onNextPage,
    onFirstPage,
    onLastPage,
  } = usePaginate(page, setPage, PAGE_SIZE, items?.length);

  const paginatedItems = useMemo(() => {
    return items?.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
  }, [items, page]);

  useEffect(() => {
    setPage(0);
  }, [week, chainData?.chainId]);

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th $small>Rank</Th>
            <Th>Trader</Th>
            <Th $rightAlign>Total Volume</Th>
            <Th $rightAlign>Total Fees</Th>
            <Th $rightAlign>Estimated Rewards ({rewardToken})</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentUserData && (
            <Tr $borderLeft={currentUserData.rank < 4} $bgGradient="#004b5c">
              <Td $padding="1rem 1.6rem" $small>
                {currentUserData.rank < 4 ? (
                  <Badge>{currentUserData.rank}</Badge>
                ) : (
                  currentUserData.rank
                )}
              </Td>
              <Td>You</Td>

              <Td $rightAlign>
                ${round(currentUserData.volume, 2).toLocaleString()}
              </Td>
              <Td $rightAlign>
                ${currentUserData.totalFeesPaid.toLocaleString()}
              </Td>
              <Td $rightAlign>
                <Align>
                  {round(currentUserData.estimatedRewards, 2).toLocaleString()}
                  <Icon
                    src={`https://synthetixio.github.io/synthetix-assets/markets/${rewardToken}.svg`}
                  />
                  <Tooltip>Claim your rewards on the Rewards page</Tooltip>
                </Align>
              </Td>
            </Tr>
          )}

          {(paginatedItems || []).map((item, i) => (
            <Tr
              $borderLeft={item.rank < 4}
              $bgGradient={getColor(item.rank)}
              key={i}
            >
              <Td $padding="1rem 1.6rem" $small>
                {item.rank < 4 ? <Badge>{item.rank}</Badge> : item.rank}
              </Td>
              <Td>
                <Icon src={`https://effigy.im/a/${item.account}.svg`} />
                {shortenAddress(item.account)}
              </Td>
              <Td $rightAlign>${round(item.volume, 2).toLocaleString()}</Td>

              <Td $rightAlign>${item.totalFeesPaid.toLocaleString()}</Td>
              <Td $rightAlign>
                {round(item.estimatedRewards, 2).toLocaleString()}
                <Icon
                  src={`https://synthetixio.github.io/synthetix-assets/markets/${rewardToken}.svg`}
                />
              </Td>
            </Tr>
          ))}

          {!filteredList?.length && !isLoading && (
            <tr>
              <td colSpan={100}>
                <Empty>No Data</Empty>
              </td>
            </tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        isNextButtonDisabled={isNextButtonDisabled}
        isPreviousButtonDisabled={isPreviousButtonDisabled}
        totalPages={totalPages}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        onFirstPage={onFirstPage}
        onLastPage={onLastPage}
        page={page + 1}
      />
    </TableContainer>
  );
};

export default LeaderBoardTable;
