import styled from "styled-components";
import Popup from "./Popup";
import { useDispatch, useSelector } from "react-redux";
import { clearError, selectError } from "../state/uiSlice";
import Button from "./Button";
import { DISCORD } from "../app/constants/urls";

const StyledErrorHandler = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;
  overflow: auto;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Text = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2rem;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const Link = styled.a`
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2.4rem;
  text-decoration: underline;
  color: var(--main);

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const ErrorHandler = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);

  const hasError = error !== null;

  return (
    <Popup
      open={hasError}
      close={() => dispatch(clearError())}
      header="An error occured"
    >
      <StyledErrorHandler>
        <Text>{`Error: ${error?.message}`}</Text>
        <Text>{`Code: ${error ? btoa(error.source) : "NO_CODE"}`}</Text>
        <Text>
          Please try again in a moment. If the error persists, you can contact
          us on{" "}
          <Link href={DISCORD} target="_blank">
            Discord
          </Link>
          .
        </Text>
        <Button
          primary
          action={() => {
            dispatch(clearError());
          }}
        >
          Try again
        </Button>
      </StyledErrorHandler>
    </Popup>
  );
};

export default ErrorHandler;
