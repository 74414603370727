import { EvmPriceServiceConnection } from "@pythnetwork/pyth-evm-js";

const LOCALHOST = "https://gateway-lon.liquify.com/api=HERMESQKDTFD77V2XMKT8V";
const KEY = "112ec71c-dd7a-4088-9bab-c63a726107a7";
const PRODUCTION = `https://hermes-mainnet.rpc.extrnode.com/${KEY}`;

const getPriceUpdateData = async (
  feedId: string,
  fallback: boolean = false
) => {
  const isLocalhost = window.location.hostname === "localhost";
  const ENDPOINT = isLocalhost || fallback ? LOCALHOST : PRODUCTION;
  const connection = new EvmPriceServiceConnection(ENDPOINT);
  const priceIds = [feedId];
  const priceUpdateData = await connection.getPriceFeedsUpdateData(priceIds);
  return priceUpdateData[0];
};

export default getPriceUpdateData;
