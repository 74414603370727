import styled from "styled-components";
import ComingSoon from "./ComingSoon";
import NewContainer from "./NewContainer";

const StyledSwitch = styled.div<{ $big?: boolean }>`
  width: 100%;
  align-items: center;
  display: flex;
  gap: 0.8rem;
  box-shadow: var(--box-shadow);
  height: ${({ $big }) => ($big ? "4rem" : "3.2rem")};
  border-radius: 0.6rem;
  background: #161616;
  padding: 0.1rem;
  background: var(--bg-1);
  border: var(--border);
`;

interface ButtonProps {
  $active: boolean;
}

const Button = styled.button<ButtonProps>`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);

  background: ${(props) => (props.$active ? "var(--bg-3)" : "var(--bg-1)")};
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

interface LabelProps {
  $active: boolean;
}

const Label = styled.p<LabelProps>`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${(props) => (props.$active ? "var(--main)" : "var(--sub)")};
`;

interface SwitchOptionType {
  label: string;
  value: string;
  comingSoon?: boolean;
  isNew?: boolean;
}

interface Props {
  switches: SwitchOptionType[];
  active: string;
  setActive: (active: string) => void;
  big?: boolean;
}

const Switch = ({ switches, active, setActive, big }: Props) => {
  return (
    <StyledSwitch $big={big}>
      {switches.map((switchOption: SwitchOptionType) => {
        const isActive = active === switchOption.value;
        return (
          <Button
            key={switchOption.value}
            $active={isActive}
            disabled={switchOption.comingSoon}
            onClick={() => setActive(switchOption.value)}
          >
            <NewContainer isNew={switchOption.isNew}>
              <ComingSoon comingSoon={switchOption.comingSoon}>
                <ButtonContent>
                  <Label $active={isActive}>{switchOption.label}</Label>
                </ButtonContent>
              </ComingSoon>
            </NewContainer>
          </Button>
        );
      })}
    </StyledSwitch>
  );
};

export default Switch;
