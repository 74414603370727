import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";
import useToken from "../contracts/use-token";
import useZap from "../contracts/use-zap";
import useLeveragedToken from "../contracts/use-leveraged-token";
import tenderlySimulation from "../utils/tenderly-simulation";
import { useEthers } from "@usedapp/core";
import { useDebounce } from "@usedapp/core/dist/esm/src/hooks";
import { DEBOUNCE_DELAY } from "../../constants/config";
import useAddresses from "../utils/use-addresses";

const useRedeemAmountOut = (
  redeemAmount_: ScaledNumber | null,
  redeemTokenAddress: string | undefined,
  redeemWithZap: boolean,
  leveragedTokenAddress: string | null,
  leveragedTokenExchangeRate: ScaledNumber | null,
  redeemTokenDecimals: number | undefined,
  exitLoad: ScaledNumber | null
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const addresses = useAddresses();
  const supportedChain = useSupportedChain();
  const [redeemAmountOut, setRedeemAmountOut] = useState<ScaledNumber | null>(
    null
  );
  const multicall = useMulticall();
  const redeemToken = useToken(redeemTokenAddress);
  const zap = useZap();
  const leveragedToken = useLeveragedToken(leveragedTokenAddress);
  const { account } = useEthers();
  const redeemAmount = useDebounce(redeemAmount_, DEBOUNCE_DELAY);

  const redeemAmountValue = redeemAmount !== null ? redeemAmount.value : null;

  useEffect(() => {
    if (!supportedChain) return;
    if (!redeemToken) return;
    if (!leveragedTokenAddress) return;
    if (!account) return;
    if (redeemAmountValue === null) return;
    if (redeemAmountValue === BigInt(0)) {
      setRedeemAmountOut(new ScaledNumber(0));
      return;
    }
    if (!leveragedToken) return;
    if (!leveragedTokenExchangeRate) return;
    if (!addresses) return;
    if (!redeemTokenDecimals) return;
    if (redeemTokenAddress === undefined) return;

    const getBalance = async () => {
      try {
        if (redeemWithZap) {
          if (!zap) return;

          const response = await tenderlySimulation([
            {
              from: account,
              to: leveragedTokenAddress,
              data: leveragedToken.interface.encodeFunctionData("approve", [
                zap.address,
                redeemAmountValue,
              ]),
            },
            {
              from: account,
              to: addresses?.ZAP_SWAP,
              data: zap.interface.encodeFunctionData("redeem", [
                redeemTokenAddress,
                leveragedTokenAddress,
                redeemAmountValue,
                0,
              ]),
            },
          ]);
          if (!response) return;
          if (!response.result) return;
          const assetChanges = response.result[1].assetChanges;
          if (!assetChanges) return;
          const rawAmount = assetChanges.find(
            (change: any) =>
              change.to === account.toLowerCase() &&
              change.assetInfo.contractAddress ===
                redeemTokenAddress.toLowerCase()
          ).rawAmount;
          const amountOut = BigInt(rawAmount);
          setRedeemAmountOut(new ScaledNumber(amountOut, redeemTokenDecimals));
        } else {
          const scaledAmount = new ScaledNumber(redeemAmountValue);
          setRedeemAmountOut(
            scaledAmount
              .sub(exitLoad ?? ScaledNumber.fromUnscaled(0))
              .mul(leveragedTokenExchangeRate)
          );
        }
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error getting Redeem Amount Out"));
      }
    };

    getBalance();
  }, [
    addresses,
    supportedChain,
    dispatch,
    multicall,
    redeemAmountValue,
    redeemToken,
    leveragedTokenAddress,
    redeemTokenAddress,
    redeemWithZap,
    zap,
    leveragedToken,
    account,
    leveragedTokenExchangeRate,
    redeemTokenDecimals,
    exitLoad,
  ]);

  const resetTrigger = redeemAmount_ !== null ? redeemAmount_.value : null;
  useEffect(() => {
    setRedeemAmountOut(null);
  }, [resetTrigger, redeemTokenAddress]);

  return redeemAmountOut;
};

export default useRedeemAmountOut;
