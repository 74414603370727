import { useEthers } from "@usedapp/core";
import { useState } from "react";

const useChainId = () => {
  const { library, active, account } = useEthers();
  const [chainId, setChainId] = useState<number | null>(null);

  const connected = active && account;

  if (library) {
    library.getNetwork().then((network) => {
      setChainId(network.chainId);
    });
  }

  return connected ? chainId : null;
};

export default useChainId;
