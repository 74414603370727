import getPriceUpdateData from "./get-price-update-data";

const submitTransactionWithPrice = async (
  feedId: string,
  transaction: (price: string) => Promise<any>
) => {
  try {
    const priceUpdateData = await getPriceUpdateData(feedId, false);
    return await transaction(priceUpdateData);
  } catch (e: any) {
    if (typeof e.message === "string") {
      if (e.message.includes("cannot estimate gas")) {
        const priceUpdateData = await getPriceUpdateData(feedId, true);
        return await transaction(priceUpdateData);
      }
    }
    throw e;
  }
};

export default submitTransactionWithPrice;
