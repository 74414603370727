import styled from "styled-components";

import Tooltip from "./Tooltip";

const InputHeaderContainer = styled.div<{ $noMargin?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.$noMargin ? "" : "1rem")};
  width: 100%;
`;

const StyledInputHeader = styled.p`
  font-size: 1.6rem;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

interface Props {
  header: string;
  tooltip: string;
  noMargin?: boolean;
}

const InputHeader = ({ header, tooltip, noMargin }: Props) => {
  return (
    <InputHeaderContainer $noMargin={noMargin}>
      <StyledInputHeader>{header}</StyledInputHeader>
      <Tooltip>{tooltip}</Tooltip>
    </InputHeaderContainer>
  );
};

export default InputHeader;
