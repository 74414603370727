import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ScaledNumber } from "scaled-number";
import { setWarning } from "../../../state/uiSlice";
import useChainData from "../utils/use-chain-data";
import useLeveragedToken from "../contracts/use-leveraged-token";

const useTvlLimitThreshold = (
  leveragedTokenAddress: string | null
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const contract = useLeveragedToken(leveragedTokenAddress);
  const chainData = useChainData();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);

  const isV2 = chainData?.v2;

  useEffect(() => {
    const getValue = async () => {
      if (!isV2) return;
      if (!contract) return;

      try {
        const value_ = await contract.minimumTvlBeforeMintLimitLifted();
        setAmount(new ScaledNumber(value_));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching tvl limit threshold"));
      }
    };

    getValue();
  }, [dispatch, contract, isV2]);

  return amount;
};

export default useTvlLimitThreshold;
