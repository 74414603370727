import { getAssetData } from "./get-asset-data";

const getLeveragedTokenSymbol = (
  assetId: string,
  leverage: number,
  isLong: boolean,
  chainId: number | null
) => {
  const assetData = getAssetData(assetId, chainId);

  if (chainId === 8453) return `${assetData.symbol}${isLong ? "BULL" : "BEAR"}`;
  return `${assetData.symbol}${leverage}${isLong ? "L" : "S"}`;
};

export default getLeveragedTokenSymbol;
