import { ScaledNumber } from "scaled-number";
import styled from "styled-components";
import { CardFrame } from "../../styles/Frames";
import Tooltip from "../../components/Tooltip";
import Button from "../../components/Button";
import AprTooltip from "../../components/AprTooltip";
import useMetadataForId from "../../app/web3/views/use-metadata-for-id";
import useBlockExplorerLink from "../../app/web3/utils/use-block-explorer-link";
import tooltipIcon from "../../assets/ui/tooltip.svg";

const StyledReward = styled(CardFrame)`
  width: 100%;
  display: flex;
  padding: 2.4rem 3.1rem;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 1.6rem;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  flex: 2;
  height: 100%;

  @media (max-width: 900px) {
    margin-top: 1.6rem;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  color: var(--sub);
`;

const MiniIcon = styled.img`
  display: none;
  height: 3.2rem;
  border-radius: 50%;

  @media (max-width: 900px) {
    display: flex;
  }
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Amount = styled.div`
  font-size: 4rem;
  font-weight: 400;
  margin-right: 0.8rem;
`;

const Unit = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--sub);
  margin-bottom: 0.5rem;
`;

const IconLink = styled.a`
  cursor: pointer;
`;

const Icon = styled.img`
  height: 6.5rem;
  margin-bottom: 2rem;
  border-radius: 50%;

  @media (max-width: 900px) {
    display: none;
  }
`;

const AprContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 0.8rem;
`;

const Apr = styled.div`
  font-size: 3.2rem;
  font-weight: 500;
  color: var(--success);
  margin-right: 0.8rem;
`;

const AprUnit = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--success);
  margin-bottom: 0.5rem;
`;

const TooltipContainer = styled.div`
  margin-bottom: 0.6rem;
`;

const TooltipCopy = styled.div`
  color: var(--main);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
`;

const TooltipLink = styled.a`
  color: var(--main);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  text-decoration: underline;
  cursor: pointer;
`;

const Price = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--sub);
  margin-top: 0.4rem;
`;

interface Props {
  header: string;
  tooltip: string;
  assetKey: string;
  price: ScaledNumber | null;
  apr?: string;
  aprCodeLink?: string;
  amount: ScaledNumber | null;
  ended?: boolean;
  claim: () => Promise<void>;
  earn: () => void;
  learnMoreLink?: string;
}

const Reward = ({
  header,
  tooltip,
  assetKey,
  price,
  apr,
  aprCodeLink,
  amount,
  ended,
  claim,
  earn,
  learnMoreLink,
}: Props) => {
  const tokenMetadata = useMetadataForId(assetKey);
  const blockExplorerLink = useBlockExplorerLink();

  if (ended && (!amount || amount.isZero())) return null;

  return (
    <StyledReward>
      <LeftColumn>
        <TopContainer>
          <HeaderContainer>
            <Header>{header}</Header>
            <Tooltip>
              <TooltipCopy>
                {tooltip}
                {learnMoreLink && (
                  <TooltipLink href={learnMoreLink} target="_blank">
                    {" Learn more"}
                  </TooltipLink>
                )}
              </TooltipCopy>
            </Tooltip>
          </HeaderContainer>
          <IconLink
            href={blockExplorerLink(tokenMetadata?.address)}
            target="_blank"
          >
            <MiniIcon
              src={tokenMetadata?.icon}
              alt={`${tokenMetadata?.symbol} Icon`}
            />
          </IconLink>
        </TopContainer>
        <AmountContainer>
          <Amount>{amount ? amount.toCryptoString() : "---"}</Amount>
          <Unit>{tokenMetadata?.symbol ?? "---"}</Unit>
        </AmountContainer>
        <Price>
          {price && amount ? amount.toUsdValue(price.toNumber()) : "$---"}
        </Price>
        {apr && (
          <AprContainer>
            <Apr>{apr}</Apr>
            <AprUnit>APR</AprUnit>
            {aprCodeLink && (
              <TooltipContainer>
                <AprTooltip success codeLink={aprCodeLink || ""} />
              </TooltipContainer>
            )}
          </AprContainer>
        )}
      </LeftColumn>
      <RightColumn>
        <IconLink
          href={blockExplorerLink(tokenMetadata?.address)}
          target="_blank"
        >
          <Icon
            src={tokenMetadata?.icon}
            alt={`${tokenMetadata?.symbol} Icon`}
          />
        </IconLink>
        <Button
          web3
          wide
          icon={!amount || amount.isZero() ? tooltipIcon : undefined}
          action={() => {
            if (!amount || amount.isZero()) earn();
            else claim();
          }}
        >
          {!amount || amount.isZero()
            ? `Earn ${tokenMetadata?.symbol ?? "---"}`
            : `Claim ${amount ? amount.toCryptoString() : "---"} ${
                tokenMetadata?.symbol ?? "---"
              }`}
        </Button>
      </RightColumn>
    </StyledReward>
  );
};

export default Reward;
