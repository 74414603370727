export const getLeaderboardUrl = (network?: number) => {
  if (network === 10) {
    return "https://api.synthetix.io/v3/optimism/lt-leaderboard";
  }
  return "https://api.synthetix.io/v3/base/lt-leaderboard";
};

export const getWeeklyRewardAmount = (network?: number) => {
  if (network === 10) {
    return 15000;
  }
  return 30000;
};

export const getRewardToken = (network?: number) =>
  network === 10 ? "OP" : "USDC";

const EPOCHS = [
  new Date("2025-01-21T00:00:00.000Z"),
  new Date("2025-01-28T00:00:00.000Z"),
  new Date("2025-02-04T00:00:00.000Z"),
  new Date("2025-02-11T00:00:00.000Z"),
  new Date("2025-02-18T00:00:00.000Z"),
  new Date("2025-02-25T00:00:00.000Z"),
];

export const getEpoch = (timestamp: number) => {
  const targetDate = new Date(timestamp);

  const index = EPOCHS.findIndex((epoch) => epoch.getTime() === timestamp);
  if (index > -1) {
    return {
      epoch: index + 1,
      progress: 0,
    };
  }

  const epochIndex = EPOCHS.findIndex(
    (epoch) => targetDate.getTime() - epoch.getTime() < 0
  );

  if (epochIndex === 0) {
    return {
      epoch: 0,
      progress: 0,
    };
  }

  if (epochIndex === -1) {
    return {
      epoch: EPOCHS.length,
      progress: 1,
    };
  }

  const percentThroughEpoch =
    (targetDate.getTime() - EPOCHS[epochIndex - 1].getTime()) /
    (EPOCHS[epochIndex].getTime() - EPOCHS[epochIndex - 1].getTime());

  return {
    epoch: epochIndex,
    progress: percentThroughEpoch,
  };
};

export const CURRENT_EPOCH = getEpoch(Date.now());
