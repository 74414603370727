import { useQuery } from "@tanstack/react-query";
import useMultipleMerkleDistributor from "../contracts/use-multiple-merkle-distributor";
import useChainData from "../utils/use-chain-data";
import { Base, useEthers } from "@usedapp/core";
import { getRewards } from "../utils/get-rewards";

const useAccountRewards = () => {
  const multipleMerkleDistributorContract = useMultipleMerkleDistributor();
  const chainData = useChainData();
  const { account } = useEthers();

  return useQuery({
    queryKey: ["accountRewards", account, chainData?.chainId],
    enabled: Boolean(multipleMerkleDistributorContract && account),
    queryFn: async () => {
      if (!(account && multipleMerkleDistributorContract)) {
        return [];
      }

      const rewards = getRewards(account, chainData?.chainId || Base.chainId);
      const rewardsWithAmount = rewards.filter(
        (reward) => Number(reward.amount) > 0
      );

      const claims = await Promise.all(
        rewardsWithAmount.map(
          async (reward) =>
            (await multipleMerkleDistributorContract.isClaimed(
              reward.index,
              reward.epoch
            )) as boolean
        )
      );

      return rewardsWithAmount.map((reward, i) => ({
        ...reward,
        hasClaimed: claims[i],
      }));
    },
  });
};

export default useAccountRewards;
