import useContract from "../utils/use-contract";
import abi from "../abis/parameter-provider-abi.json";
import abiV2 from "../abis/parameter-provider-v2-abi.json";
import useAddresses from "../utils/use-addresses";
import useChainData from "../utils/use-chain-data";

const useParameterProvider = () => {
  const addresses = useAddresses();
  const chainData = useChainData();
  const isV2 = chainData?.v2;
  return useContract(addresses?.PARAMETER_PROVIDER, isV2 ? abiV2 : abi);
};

export default useParameterProvider;
