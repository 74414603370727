export interface CrytoMetadataType {
  name: string;
  volume: number;
  color: string;
  coingeckoId?: string;
  tvlCap?: number;
}

export const ASSET_METADATA: { [key: string]: CrytoMetadataType } = {
  BERA: {
    name: "Berachain",
    volume: 10833920.965601815,
    color: "#814625",
    coingeckoId: "berachain",
    tvlCap: 700_000,
  },
  TRUMP: {
    name: "Official Trump",
    volume: 10833920.965601815,
    color: "#f4de92",
    coingeckoId: "official-trump",
    tvlCap: 700_000,
  },
  WIF: {
    name: "dogwifhat",
    volume: 10833920.965601815,
    color: "#a19484",
    coingeckoId: "dogwifhat",
    tvlCap: 400_000,
  },
  PNUT: {
    name: "Peanut the Squirrel",
    volume: 10833920.965601815,
    color: "#68a2d3",
    coingeckoId: "peanut-the-squirrel",
    tvlCap: 500_000,
  },
  BONK: {
    name: "Bonk",
    volume: 10833920.965601815,
    color: "#FDDE03",
    coingeckoId: "bonk",
    tvlCap: 400_000,
  },
  FARTCOIN: {
    name: "Fartcoin",
    volume: 10833920.965601815,
    color: "#1C1C1C",
    coingeckoId: "fartcoin",
    tvlCap: 400_000,
  },
  BTC: {
    name: "Bitcoin",
    volume: 132755903.00179607,
    color: "#F6931A",
    coingeckoId: "bitcoin",
    tvlCap: 11_100_000,
  },
  ETH: {
    name: "Ethereum",
    volume: 128083833.58935325,
    color: "#627EEA",
    coingeckoId: "ethereum",
    tvlCap: 7_000_000,
  },
  SOL: {
    name: "Solana",
    volume: 27431649.92932513,
    color: "linear-gradient(45deg, #904AF7, #75F8A3)",
    coingeckoId: "solana",
    tvlCap: 4_400_000,
  },
  PEPE: {
    name: "Pepe",
    volume: 10833920.965601815,
    color: "#609542",
    tvlCap: 300_000,
  },
  LINK: {
    name: "Chainlink",
    volume: 9000787.623370336,
    color: "#295ADA",
    coingeckoId: "chainlink",
  },
  RUNE: {
    name: "THORChain",
    volume: 1026566.2066446682,
    color: "pink",
  },
  ARB: {
    name: "Arbitrum",
    volume: 2224939.468004701,
    color: "pink",
  },
  INJ: {
    name: "Injective",
    volume: 3178074.872622407,
    color: "pink",
  },
  WLD: {
    name: "Worldcoin",
    volume: 617908.4659161129,
    color: "pink",
  },
  FTM: {
    name: "Fantom",
    volume: 1343131.371973468,
    color: "pink",
  },
  ETHBTC: {
    name: "ETH / BTC",
    volume: 2343040.3523589442,
    color: "linear-gradient(45deg, #627EEA, #F7931A)",
  },
  XAU: {
    name: "Gold",
    volume: 1654818.5368831265,
    color: "pink",
  },
  DOGE: {
    name: "Dogecoin",
    volume: 1720334.3311083089,
    color: "#BA9F32",
    tvlCap: 400_000,
  },
  OP: {
    name: "Optimism",
    volume: 410996.8507908914,
    color: "#FF0320",
    coingeckoId: "optimism",
  },
  MATIC: {
    name: "Polygon",
    volume: 1542487.4694148912,
    color: "pink",
  },
  BNB: {
    name: "BNB",
    volume: 819903.9032273412,
    color: "pink",
  },
  AAVE: {
    name: "Aave",
    volume: 2000287.8407759585,
    color: "pink",
  },
  LDO: {
    name: "Lido",
    volume: 1055113.382430128,
    color: "pink",
  },
  APE: {
    name: "ApeCoin",
    volume: 246119.6165460539,
    color: "pink",
  },
  DYDX: {
    name: "dYdX",
    volume: 154674.8472106387,
    color: "pink",
  },
  GMX: {
    name: "GMX",
    volume: 317589.8339609976,
    color: "pink",
  },
  XRP: {
    name: "Ripple",
    volume: 558263.4937716108,
    color: "pink",
    tvlCap: 4_400_000,
  },
  MKR: {
    name: "Maker",
    volume: 791719.4404565691,
    color: "pink",
  },
  DOT: {
    name: "Polkadot",
    volume: 537006.2187104354,
    color: "pink",
  },
  BCH: {
    name: "Bitcoin Cash",
    volume: 592466.1844376766,
    color: "pink",
  },
  CRV: {
    name: "Curve",
    volume: 261539.70873110814,
    color: "pink",
  },
  XAG: {
    name: "Silver",
    volume: 16589.59611278871,
    color: "pink",
  },
  BLUR: {
    name: "Blur",
    volume: 109112.62555734592,
    color: "pink",
  },
  YFI: {
    name: "Yearn Finance",
    volume: 93663.74007813993,
    color: "pink",
  },
  APT: {
    name: "Aptos",
    volume: 177500.2789590708,
    color: "pink",
  },
  RNDR: {
    name: "Render",
    volume: 41718.58954635618,
    color: "pink",
  },
  FLOKI: {
    name: "Floki",
    volume: 199624.54301231916,
    color: "pink",
  },
  USDT: {
    name: "USD Tether",
    volume: 5803.225551053933,
    color: "pink",
  },
  EUR: {
    name: "Euro",
    volume: 144895.5257374331,
    color: "pink",
  },
  UNI: {
    name: "Uniswap",
    volume: 13308.236459050526,
    color: "pink",
  },
  SUI: {
    name: "Sui",
    volume: 35331.745841259326,
    color: "#4CA3FF",
  },
  COMP: {
    name: "Compound",
    volume: 73424.89660468239,
    color: "pink",
  },
  AVAX: {
    name: "Avalanche",
    volume: 71421.64152450104,
    color: "pink",
  },
  STETH: {
    name: "Staked Ether",
    volume: 108278.68692898203,
    color: "pink",
  },
  ADA: {
    name: "Cardano",
    volume: 96970.907757587,
    color: "pink",
  },
  AXS: {
    name: "Axie Infinity",
    volume: 12728.045831890622,
    color: "pink",
  },
  ATOM: {
    name: "Cosmos",
    volume: 5812.075194879981,
    color: "pink",
  },
  RPL: {
    name: "Rocket Pool",
    volume: 11281.17561989204,
    color: "pink",
  },
  UMA: {
    name: "UMA",
    volume: 12813.274596090814,
    color: "pink",
  },
  FXS: {
    name: "Frax Share",
    volume: 34657.87466189016,
    color: "pink",
  },
  ALGO: {
    name: "Algorand",
    volume: 29547.06563603938,
    color: "pink",
  },
  FIL: {
    name: "Filecoin",
    volume: 6562.104358086996,
    color: "pink",
  },
  XMR: {
    name: "Monero",
    volume: 25661.392664065897,
    color: "pink",
  },
  PERP: {
    name: "Perpetual Protocol",
    volume: 14821.434817120413,
    color: "pink",
  },
  TRX: {
    name: "Tron",
    volume: 7990.149345994826,
    color: "pink",
  },
  ONE: {
    name: "Harmony",
    volume: 48394.10191027487,
    color: "pink",
  },
  LTC: {
    name: "Litecoin",
    volume: 18223.71753677956,
    color: "pink",
  },
  AUD: {
    name: "Australian Dollar",
    volume: 39112.37533061786,
    color: "pink",
  },
  NEAR: {
    name: "NEAR Protocol",
    volume: 11449.554657138471,
    color: "pink",
  },
  BAL: {
    name: "Balancer",
    volume: 31282.36898251602,
    color: "pink",
  },
  KNC: {
    name: "Kyber Network Crystal v2",
    volume: 30485.22654988004,
    color: "pink",
  },
  ZRX: {
    name: "0x Protocol",
    volume: 2161.699521106516,
    color: "pink",
  },
  ETC: {
    name: "Ethereum Classic",
    volume: 1363.7297578664786,
    color: "pink",
  },
  SHIB: {
    name: "Shiba Inu",
    volume: 15453.08435636167,
    color: "pink",
  },
  ICP: {
    name: "Internet Computer",
    volume: 5074.722077976167,
    color: "pink",
  },
  XLM: {
    name: "Stellar",
    volume: 183.58096674027195,
    color: "pink",
  },
  ZIL: {
    name: "Zilliqa",
    volume: 306.7772982326141,
    color: "pink",
  },
  GBP: {
    name: "British Pound sterling",
    volume: 2594.8315087452324,
    color: "pink",
  },
  MAV: {
    name: "Maverick Protocol",
    volume: 2375.822865686102,
    color: "pink",
  },
  CELO: {
    name: "Celo",
    volume: 500.0725690172597,
    color: "pink",
  },
  EOS: {
    name: "EOS",
    volume: 508.86334500163923,
    color: "pink",
  },
  SEI: {
    name: "Sei",
    volume: 1024.234897532337,
    color: "pink",
  },
  XTZ: {
    name: "Tezos",
    volume: 500.134350274773,
    color: "pink",
  },
  ZEC: {
    name: "Zcash",
    volume: 668.2904101854704,
    color: "pink",
  },
  SUSHI: {
    name: "Sushi",
    volume: 668.2904101854704,
    color: "pink",
  },
  ENJ: {
    name: "Enjin Coin",
    volume: 668.2904101854704,
    color: "pink",
  },
  FLOW: {
    name: "Flow",
    volume: 668.2904101854704,
    color: "pink",
  },
  "1INCH": {
    name: "1inch",
    volume: 668.2904101854704,
    color: "pink",
  },
};

export const ALL_ASSET_IDS = Object.keys(ASSET_METADATA);
