import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --bg: #06061B;
        --bg-1: #0B0B22;
        --bg-2: rgba(45, 45, 56, 0.5);
        --bg-3: rgba(45, 45, 56, 1);
        --main: #E7E7E7;
        --sub: #9999AC;
        --accent: #5e5e70;
        --primary: #00D1FF;
        --secondary: #34EDB3;
        --primary-sub: #213235;
        --primary-bg: #171f21;
        --success: #2BBF6F;
        --success-bg: #191D1A;
        --warning: #FC8738;
        --warning-bg: #522100;
        --disabled-text: #6D6D6D;
        --error: #C8385A;
        --border-radius: 0.5rem;
        --gradient: linear-gradient(to right, var(--secondary), var(--primary));

        --border: solid 1px var(--bg-3);
        --shadow: rgba(0, 0, 0, 0.4);
        --box-shadow: 2px 3px 7px 0px rgba(18, 18, 18, 0.3);
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: "Inter", serif;
    }

    div {
        color: var(--main);
    }

    button {
        background: none;
        border: none;
        outline: none;
    }
    
    input {
        border: none;
        outline: none;
        background: none;
        -moz-appearance: textfield;
        appearance: textfield;

        // Remove arrows from number input
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            display: none;
        }
    }

    a {
        text-decoration: none;
    }
`;

const GlobalStyles = (): JSX.Element => {
  return <GlobalStyle />;
};

export default GlobalStyles;
